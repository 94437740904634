import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useEffect, useState } from "react";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import TextField from "@presentation/components/inputs/TextField";
import TotalSelect from "@presentation/components/TotalSelect";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { useNavigate } from "react-router-dom";
import { User } from "@domain/entities/Users";
import UserField from "@presentation/components/UserField";
import { listScopes } from "@adapters/store/scopes/thunk";
import { getAdminScopes, getScopesIsPending } from "@adapters/store/scopes/slice";
import ScopePermissionField from "@presentation/components/ScopePermissionField";
import { getFavoriteReports, getReports, getReportsIsPending } from "@adapters/store/reports/slice";
import { clearUsersError, getUsers, getUsersError } from "@adapters/store/users/slice";
import { createUser, listUsers } from "@adapters/store/users/thunk";
import { getFavorite, listReports } from "@adapters/store/reports/thunk";
import { listGroups } from "@adapters/store/groups/thunk";
import { Group, ScopesAndPermissionsRequest } from "@domain/entities/Groups";
import { Scope } from "@domain/entities/Scopes";
import { Report } from "@domain/entities/Reports";
import { getGroups, getGroupsIsPending } from "@adapters/store/groups/slice";
import SummaryUser from "@presentation/components/users/SummaryUserCreateUpdate";
import { sortAsc, stringComparatorWithFormatter } from "@core/utils/StringTools";
import TotalToggle from "@presentation/components/TotalToggle";
import { uniq } from "@core/utils/ArrayTools";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { ScopeColumn, getReportsWithoutDuplicates, getSimulatedScopesRights } from "@presentation/utils/UserUtils";
import { useTranslation } from "react-i18next";
import TotalSearchSelect from "@presentation/components/TotalSearchSelect";
import { atlas } from "@assets/atlas";
import TotalCheckbox from "@presentation/components/TotalCheckbox";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";
import { StarFilledIcon } from "@presentation/components/Icons/StartFilledIcon";

const CreateUser = (): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isInternal, setIsInternal] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<string>("");
  const [department, setDepartment] = useState<string>("");

  const [searchReport, setSearchReport] = useState<string>("");
  const [selectedReports, setSelectedReports] = useState<number[]>([]);

  const [selectedScopes, setRawSelectedScopes] = useState<ScopeColumn[]>([]);
  const [selectedScope, setSelectedScope] = useState<number>(-1);

  const [selectedGroups, setRawSelectedGroups] = useState<Group[]>([]);

  const [country, setCountry] = useState<string>("");

  const [isValid, setIsValid] = useState<boolean>(false);

  const error = useAppSelector(getUsersError);
  const scopesList = useAppSelector(getAdminScopes);
  const reportsList = useAppSelector(getReports);
  const usersList = useAppSelector(getUsers);
  const groupsList = useAppSelector(getGroups);
  const scopesIsPending = useAppSelector(getScopesIsPending);
  const reportsIsPending = useAppSelector(getReportsIsPending);
  const favorite = useAppSelector(getFavoriteReports);
  const groupsIsPending = useAppSelector(getGroupsIsPending);
  const permissionNames = [t("rights.admin"), t("rights.read_and_edit"), t("rights.read_only")];

  const [filteredReports, setFilteredReports] = useState<Report[]>(reportsList);

  const path = "/users";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("navbar.manage_users")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("users_create_update.create_title")}
    </Link>,
  ];

  const setSelectedScopes = (scopes: ScopeColumn[]): void => {
    scopes.sort((scopeA, scopeB) => sortAsc(scopeA.scopeName, scopeB.scopeName));
    setRawSelectedScopes(scopes);
  };

  const setSelectedGroups = (groups: Group[]): void => {
    groups.sort((groupA, groupB) => sortAsc(groupA.groupName, groupB.groupName));
    setRawSelectedGroups(groups);
  };

  const handleFirstNameChange = (value: string): void => {
    setFirstName(value);
  };
  const handleLastNameChange = (value: string): void => {
    setLastName(value);
  };
  const handleEmailChange = (value: string): void => {
    setEmail(value);
  };
  const handleIsInternalChange = (_event: React.MouseEvent<HTMLElement>, value: string | null): void => {
    setIsInternal(value !== null && value !== undefined && value === "true");
  };
  const handleOrgChange = (value: string): void => {
    setOrganisation(value);
  };
  const handleDeptChange = (value: string): void => {
    setDepartment(value);
  };
  const handleCountryChange = (value: string): void => {
    setCountry(value);
  };
  const handleSearchReportChange = (value: string): void => {
    setSearchReport(value);
  };

  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i; // old one: /^[\w.+-']+@[\w-]+\.[\w.-]+$/i

  const handleSelectGroup = (newValue: number): void => {
    const newArr: Group[] = [...selectedGroups];
    const valueArr = newArr.map((item: Group) => {
      return item.id;
    });
    const isDuplicate = valueArr.some((item: number) => {
      return item === newValue;
    });
    if (!isDuplicate) {
      const tmp = groupsList.find((x) => x.id === newValue);
      if (tmp) newArr.push(tmp);
      setSelectedGroups(newArr);
    }
  };

  const handleGroupRemove = (idx: number): void => {
    const newArr: Group[] = [...selectedGroups];
    newArr.splice(idx, 1);
    setSelectedGroups(newArr);
  };

  const handleColumnValueChange = (idx: number, value: number, isDuplicate?: boolean): void => {
    let newArr;
    if (isDuplicate) {
      newArr = [...selectedScopes];
      newArr.splice(idx, 0, { ...selectedScopes[idx] });
      newArr[idx + 1].scopePermissionId = value;
      newArr[idx + 1].scopePermission = permissionNames[value - 1];
    } else if (value !== -1) {
      newArr = [...selectedScopes];
      newArr[idx].scopePermissionId = value;
      newArr[idx].scopePermission = permissionNames[value - 1];
    } else {
      newArr = selectedScopes.filter((x: ScopeColumn, xId: number) => xId !== idx);
    }
    setSelectedScopes(newArr);
  };

  const handleSubmit = async (): Promise<void> => {
    if (groupsIsPending || scopesIsPending || reportsIsPending) return;
    const sApArr: ScopesAndPermissionsRequest[] = [];
    selectedScopes.forEach((x: ScopeColumn) => sApArr.push({ scopeId: x.scopeId, permissionId: x.scopePermissionId }));
    await dispatch(
      createUser({
        email: email.trim(),
        isInternal: isInternal,
        profile: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          organisation: organisation.trim(),
          country: country.trim(),
          department: department?.trim(),
        },
        scopes: uniq(sApArr),
        reports: uniq(selectedReports),
        groups: uniq(selectedGroups.map((x) => x.id)),
      })
    );
    dispatch(listUsers());
    if (!error) navigate(path);
  };

  const checkAlreadyExists = (value: string): boolean => {
    return usersList.some((x: User) => x.email === value.trim());
  };

  const reportIsInGroup = (reportId: number): boolean =>
    selectedGroups.some((group) => group.reports.map((report) => report.id).includes(reportId));

  const getGroupCanAccessToReport = (reportId: number): Group[] =>
    selectedGroups.filter((group) => group.reports.map((r) => r.id).includes(reportId));

  useEffect(() => {
    dispatch(listScopes());
    dispatch(listGroups());
    dispatch(listUsers());
    dispatch(listReports());
    dispatch(getFavorite());
    setFilteredReports(reportsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (reportsIsPending) return;
    const filteredRows = reportsList.filter((row) => stringComparatorWithFormatter(row.reportName, searchReport));
    setFilteredReports(filteredRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsList, searchReport]);

  useEffect(() => {
    if (scopesIsPending || selectedScope === -1) return;
    const tmpSelectedScope = scopesList.find((x) => x.id === selectedScope);
    const isDuplicate = selectedScopes.some((x: ScopeColumn) => x.scopeId === tmpSelectedScope?.id);
    if (isDuplicate) return;
    const newArr: ScopeColumn[] = [...selectedScopes];
    newArr.push({
      scopeId: tmpSelectedScope?.id || 0,
      scopeName: tmpSelectedScope?.scopeName || "",
      scopePermissionId: 0,
      scopePermission: "",
    });
    setSelectedScopes(newArr);
    setSelectedScope(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope]);

  const toggleCheckSingleColumn = (columnId: number): void => {
    let newArr;
    if (selectedReports.includes(columnId)) {
      newArr = selectedReports.filter((id) => id !== columnId);
    } else {
      newArr = [...selectedReports];
      newArr.push(columnId);
    }
    setSelectedReports(newArr);
  };

  const handleFormIsValid = (): boolean => {
    const conditions = {
      firstName: !!firstName.trim(),
      lastName: !!lastName.trim(),
      organisation: !!organisation.trim(),
      country: !!country.trim(),
      email: !!email.trim(),
      alreadyExists: !checkAlreadyExists(email),
      isEmailValid: validateEmail.test(email),
      selectedScopes: !selectedScopes.some((scope) => scope.scopePermissionId === 0),
    };
    const result = Object.values(conditions).every((x) => x === true);
    return result;
  };

  useEffect(() => {
    setIsValid(handleFormIsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    email,
    isInternal,
    organisation,
    country,
    department,
    searchReport,
    selectedReports,
    selectedScopes,
    selectedScope,
    selectedGroups,
  ]);

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      {error && (
        <Alert severity="error" onClose={() => dispatch(clearUsersError())} sx={{ my: 2 }}>
          {error?.message}
        </Alert>
      )}
      <Typography sx={{ my: "28px", color: "#374649" }} variant="h2">
        {t("users_create_update.create_title")}
      </Typography>
      <Grid container item direction="row" justifyContent="end" alignItems="end">
        <TotalButton
          id="save-new-user-button"
          text={t("users_create_update.save_new_user")}
          icon=""
          height={22}
          disabled={!isValid}
          onClick={handleSubmit}
        />
      </Grid>
      <CollapsibleCard title={t("users_create_update.1_profile_information")} sx={{ mt: "30px" }} isExpanded={true}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
          <TextFieldData
            id="first-name"
            label={t("users_create_update.first_name_input")}
            value={firstName}
            error={firstName.trim().length === 0 && firstName.length > 0}
            onChange={handleFirstNameChange}
            width="30%"
          />
          <TextFieldData
            id="last-name"
            label={t("users_create_update.last_name_input")}
            error={lastName.trim().length === 0 && lastName.length > 0}
            value={lastName}
            onChange={handleLastNameChange}
            width="30%"
          />
          <TotalToggle
            id="isInternal"
            label={t("users_create_update.user_type_input")}
            buttons={[
              { value: "true", label: t("users_create_update.user_type_internal"), selected: isInternal },
              { value: "false", label: t("users_create_update.user_type_external"), selected: !isInternal },
            ]}
            onChange={handleIsInternalChange}
            value={isInternal}
            helpText={t("users_create_update.user_type_tooltip")}
          />
          <TextFieldData
            id="email"
            label={t("users_create_update.email_input")}
            value={email}
            error={
              checkAlreadyExists(email) ||
              (email.length > 0 && email.length <= 1) ||
              (email.length > 0 && !validateEmail.test(email))
            }
            onChange={handleEmailChange}
            width="30%"
          />
          <TextFieldData
            id="organisation"
            label={t("users_create_update.organization_input")}
            value={organisation}
            error={organisation.trim().length === 0 && organisation.length > 0}
            onChange={handleOrgChange}
            width="30%"
          />
          <TotalSearchSelect
            label={t("users_create_update.country_input")}
            sx={{ width: "30%" }}
            items={[...atlas, { value: "", label: "" }]}
            value={country}
            onValueChange={handleCountryChange}
            error={false}
          />
          <TextFieldData
            id="department"
            label={t("users_create_update.department_input")}
            value={department}
            onChange={handleDeptChange}
            width="30%"
          />
        </Box>
      </CollapsibleCard>
      <CollapsibleCard title={t("users_create_update.2_add_user_to_groups")} sx={{ mt: "8px" }}>
        <TotalSelect
          label={t("users_create_update.select_group")}
          items={groupsList.map((x: Group) => ({ id: x.id, optionName: x.groupName }))}
          sx={{ minWidth: "350px" }}
          onChange={handleSelectGroup}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          {selectedGroups.map((x: Group, idx: number) => {
            return (
              <UserField
                key={x.id + "-" + x.groupName}
                className={x.id + "-" + x.groupName}
                group={x}
                identifier={idx}
                width="49%"
                handleValue={handleGroupRemove}
                sx={{ mx: 1 }}
              />
            );
          })}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}></Box>
      </CollapsibleCard>
      <CollapsibleCard title={t("users_create_update.3_scopes_and_permissions")} sx={{ mt: "8px" }}>
        <TotalSelect
          label={t("users_create_update.select_scopes")}
          items={scopesList.map((x: Scope) => ({ id: x.id, optionName: x.scopeName }))}
          sx={{ minWidth: "350px" }}
          onChange={(e: number) => setSelectedScope(e)}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          {selectedScopes.map((x: ScopeColumn, idx: number) => {
            return (
              <ScopePermissionField
                key={x.scopeId + "-" + x.scopeName}
                className={x.scopeId + "-" + x.scopeName}
                scope={x}
                scopeIdx={idx}
                permissionId={x.scopePermissionId}
                handleValue={(idxa: number, e: number) => handleColumnValueChange(idxa, e)}
              />
            );
          })}
          {selectedGroups
            .flatMap((g) =>
              g.scopesAndPermissions.map((s) => ({
                group: g,
                scope: {
                  scopeId: s.scope.id,
                  scopeName: s.scope.scopeName,
                  scopePermission: s.permission.permissionName,
                  scopePermissionId: s.permission.id,
                },
              }))
            )
            .map((s, idx) => {
              return (
                <ScopePermissionField
                  key={s.scope.scopeId + "-" + s.scope.scopeName + "-" + s.group.id}
                  className={s.scope.scopeId + "-" + s.scope.scopeName + "-" + s.group.id}
                  scope={s.scope}
                  group={s.group}
                  scopeIdx={idx}
                  permissionId={s.scope.scopePermissionId}
                  handleValue={(idxa: number, e: number) => handleColumnValueChange(idxa, e)}
                />
              );
            })}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}></Box>
      </CollapsibleCard>
      <CollapsibleCard title={t("users_create_update.4_report_access")} sx={{ mt: "8px" }}>
        <TextField
          id="search-report"
          label={t("users_create_update.4_description")}
          value={searchReport}
          onChange={handleSearchReportChange}
          width="30%"
          endAdornment={
            <InputAdornment position="end">
              <SearchRoundedIcon color="secondary" />
            </InputAdornment>
          }
        />
        {filteredReports && (
          <Box sx={{ display: "flex", flexWrap: "wrap", maxHeight: "300px", justifyContent: "space-between" }}>
            {filteredReports.map((report: Report) => {
              return (
                <TotalTooltip
                  key={report.id}
                  title={
                    reportIsInGroup(report.id)
                      ? (() => {
                          const groupNames = getGroupCanAccessToReport(report.id).map((g) => g.groupName);
                          return t("users_create_update.user_already_access_with_group", {
                            groupName: groupNames.join(", "),
                            count: groupNames.length,
                          });
                        })()
                      : ""
                  }
                  placement="bottom"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "48%",
                      mt: 2,
                      borderBottom: 1,
                      height: "39px",
                      cursor: "pointer",
                    }}
                    onClick={() => !reportIsInGroup(report.id) && toggleCheckSingleColumn(report.id)}
                  >
                    <Box>
                      <TotalCheckbox
                        checked={selectedReports.includes(report.id) || reportIsInGroup(report.id)}
                        disabled={reportIsInGroup(report.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": report.id + "" }}
                      />
                    </Box>
                    {favorite.includes(report.id) && (
                      <StarFilledIcon
                        sx={{
                          color: theme.custom.colors.brand.primary[500],
                          height: "14px",
                          my: "auto",
                          mr: "4px",
                          display: "flex",
                        }}
                      />
                    )}
                    <Typography variant="h6" sx={{ userSelect: "none", transform: "translateY(1px)" }}>
                      {report.reportName}
                    </Typography>
                  </Box>
                </TotalTooltip>
              );
            })}
          </Box>
        )}
      </CollapsibleCard>
      <CollapsibleCard title={t("users_create_update.5_user_summary")} sx={{ mt: "8px" }}>
        <SummaryUser
          firstName={firstName}
          lastName={lastName}
          organisation={organisation}
          department={department}
          isInternal={isInternal}
          selectedScopes={getSimulatedScopesRights([
            ...selectedScopes,
            ...selectedGroups.flatMap((g) =>
              g.scopesAndPermissions.map((s) => ({
                scopeId: s.scope.id,
                scopeName: s.scope.scopeName,
                scopePermission: s.permission.permissionName,
                scopePermissionId: s.permission.id,
              }))
            ),
          ])}
          selectedGroups={selectedGroups}
          selectedReports={getReportsWithoutDuplicates([
            ...selectedReports,
            ...selectedGroups.flatMap((g) => g.reports).map((r) => r.id),
          ])}
          reportsList={reportsList}
        />
      </CollapsibleCard>
    </Grid>
  );
};

export default CreateUser;
